
.dropdown-items {
 margin-right: 30px;
}

.navbar {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-size: 1rem;
}

.aactive {
  color: #D39801 !important;
  background-color: transparent !important;
  cursor: pointer;
  font-weight: bold;
  display: relative;
 
}
.nav-item-select {
  position: relative;
  display: flex;
  top:4px
}

.nav-item-select button {
  background-color: transparent;
  color: #FFD058;
  font-size: 16px;
  border: none;
  font-family: future;
  
}
.navbar-divider{
  border:1px solid #FFD058;;
  position: relative;
  top:7px;
  height: 17px;
  margin-right:5px;
  margin-left:5px;
}

@media screen and (min-width: 993px)  {
  .navbar-right{
   position: absolute !important;
   right:30px !important;
  }
  .navbar-left{
   position: absolute !important;
   left:30px !important;
  }
  .navbar-logo-action{
   position: relative;
   left: 600px; 
  }  
}

@media screen and (max-width: 1000px)  {
  .navbar-divider{
    top:3px !important;  
  }  
}
.navbar-black{
 background-color: #383E42 !important;
 transition: 1s;
}  
.navbar-transparent{
  background-color: transparent !important;
 }  

 .navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl{
  background-color: transparent !important;
 }  

 .navbar-logo-action{
  opacity: 1 !important;
  transition: 2s;
 }  
 .navbar-logo-first-action{
  opacity: 0;
 } 

/*
.navbar {
  background-color: black ;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: center;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: black;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: -15px;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
  .nav-item-select {
    left: -25px;
  }
}

.nav-item-select {
  top: 27px;
  position: relative;
}

.nav-item-select button {
  background-color: black;
  color: white;
  font-size: 17px;
  margin-right: -50px;
  margin-left: -5px;
  border: none;
}
.aactive {
  color: #e4c473 !important;
  background-color: transparent !important;
  cursor: pointer;
}
@media screen and (min-width: 280px) and (max-width: 389px) {
  .nav-item {
    position: relative;
    top: -220px;
  }
}

@media screen and (min-width: 390px) and (max-width: 539px) {
  .nav-item {
    position: relative;
    top: -380px;
  }
}

@media screen and (min-width: 540px) and (max-width: 700px) {
  .nav-item {
    position: relative;
    top: -240px;
  }
}

@media screen and (min-width: 700px) and (max-width: 819px) {
  .nav-item {
    position: relative;
    top: -550px;
  }
}

@media screen and (min-width: 820px) and (max-width: 960px) {
  .nav-item {
    position: relative;
    top: -700px;
  }
}


*/