.footer-container {
  background-color:  #383E42;
  /*padding: 4rem 0 2rem 0; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
}

.footer-container-home {
  background-color:  #383E42;
  width: 100%;
  height: 100%;
  padding-top:10rem;
 
} 
@font-face {
  font-family: amelinda;
  src: url(../../public/Amelinda.ttf);
}
.footer-newsletter-text{
  font-family: amelinda;
  font-size: 2.5rem;
  color:#8E7356;
  margin-right: 30px;
  margin-bottom: 0rem;
}

/*
.footer-subscription > p {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 32px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

.footer-input {
  outline: none;
  font-size: 16px;
  margin-top: 40px;
  border: none;
  background-color: transparent;
  margin-right: 120px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 5px 20px;
}

.input-areas {
  display: flex;
  flex-direction: column;
  background-color: #173752;
  position: relative;
  top: -150px;
  width: 40%;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 150px;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: space-evenly;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 50px;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 5px;
  margin-top: 1rem;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
}

.footer-link-items a:hover {
  transition: 0.3s ease-out;
}

.footer-email-form h2 {
  margin-bottom: 2rem;
}

.footer-input::placeholder {
  color: #b1b1b1;
}

.social-icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 200px;
}
.social-icons .linkedin {
  color: #007bb6 !important;
  font-size: 1.5rem;
}

.social-icons .twitter {
  color: #00aced !important;
  font-size: 1.5rem;
}
.social-icons .instagram {
  color: transparent;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 1.7rem;
}
@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .input-areas {
    background-color: #173752;
    position: relative;

    width: 100%;
  }
  .footer-links {
    display: block;
    width: 80%;
  }
  .footer-input {
    width: 50%;
  }
  .footer-link-items {
    position: relative;
    top: -200px;
  }
  .footer-text {
    position: relative;
    top: -200px;
  }
}

@media screen and (min-width: 768px) {
  .footer-text {
    position: relative;
    bottom: 300px;
    left: 200px;
  }
}

.footer-text {
  text-align: center;
  color: white;
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
}
.footer-line {
  border-top: 1px solid #e4c473;
  margin-bottom: 10px;
  width: 80%;
}

.footer-line-item {
  border-top: 1px solid #e4c473;
  margin-bottom: 10px;
  width: 200px;
}

.footer-button {
  background-color: #e4c473;
  color: black;
  padding: 10px 45px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
  font-size: 15px;
  margin-left: 100px;
  margin-top: 50px;
  border: none;
}

.footer-link-title {
  color: white;
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
  margin-left: 5px;
}
*/