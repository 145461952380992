body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  margin: 2em 0 2em 2em;
  padding-bottom: 1em;
  border-bottom: 1px solid;
}

.header a {
  margin: 0 1em 0 1em;
  font-size: 1.2em;
  text-decoration: none;
}

.content {
  margin-left: 2em;
  font-size: 1.5em;
}

/*.message {
  color: red;
  font-size: 0.9em;
  position: relative;
  top: 300px;
  left: -160px;
  height: 50px;
}

@media screen and (max-width: 768px) {
  .message {
    position: relative;
    top: -280px;
    left: 180px;
  }
}

@media screen and (min-width: 819px) and (max-width: 830px) {
  .message {
    position: relative;
    top: 300px;
    left: -100px;
  }
}
*/


