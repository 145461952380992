* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family:Arial, Helvetica, sans-serif
}
@font-face {
  font-family: future;
  src: url(../public/Font.ttf);
}

@font-face {
  font-family: roboto;
  src: url(../public/Roboto-Regular.ttf);
}

@font-face {
  font-family: mynaruse;
  src: url(../public/Mynaruse.ttf);
}

.home {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.App {
  font-family: sans-serif;
  text-align: center;
}
.total-area{
  display: flex;
  justify-content:space-around;
  padding:80px;
}
.about-vector{
  position: relative;
  top: -80px;
  left: 450px;
}

.divider{
  border-right: 1px solid #CD6A6D;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left:30px;
}
.mission-area{
  padding-right: 20px;
  padding-left: 20px;
  width: 50%;
}
.vision-area{
  padding-left: 20px;
  width: 50%;
}

.divider-container{
  display: flex;
  align-items: center;
  padding: 30px 80px 70px 80px;
}

.divider-border{
  border-bottom: 1px solid #CD6A6D;
  width: 100%; 
}

.mv-text-area{
  
  
  padding-right: 100px;
}
.info-text-area{
  text-align: left;
  padding-right: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
}
.previous-image {
  background: url('/src/images/company.png') center center/cover no-repeat; 
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.about-image {
  background: url('/src/images/history.png') center center/cover no-repeat; 
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.vision-image {
  background: url('/src/images/about-us.png') center center/cover no-repeat; 
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}


.ceo-image {
  background: url('/src/images/ceo-page.png') center center/cover no-repeat; 
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.contact-image {
  background: url('/src/images/contact.png') center center/cover no-repeat; 
  height: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}
 
.real-estate {
  background: url('/src/images/realestate3.jpeg') center center/cover no-repeat; 
  height: 900px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}
.real-estate-action{
  position: relative;
  top:100px
}
.real-estate-blur {
  background: url('/src/images/blur-realestate.jpeg') center center/cover no-repeat; 
  height: 900px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.agriculture {
  background: url('/src/images/agriculture2.png') center center/cover no-repeat; 
  height: 900px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}
.agriculture-blur {
  background: url('/src/images/blur-agri.jpeg') center center/cover no-repeat; 
  height: 900px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}
.agriculture-action{
  position: relative;
  top:100px;
}
.medical {
  background: url('/src/images/medical.jpg') center center/cover no-repeat; 
  height: 900px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}
.medical-blur {
  background: url('/src/images/blur-medical.jpeg') center center/cover no-repeat; 
  height: 900px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}
.medical-action{
  position: relative;
  top:100px;
}
.ceo-title{
  padding-top: 80px;
padding-left: 80px;
font-size: 35px;
font-weight: bold;
padding-bottom: 20px;
}
.about-desc{
  padding-right:100px;
  padding-left: 80px;
  padding-bottom: 50px;
  white-space: pre-line;  
vertical-align: bottom;
}

.about-area{
  padding:100px
}
.about-image2 { 
  width: 80%;
  padding:30px
}

.about-ceo-image { 
  width: 100%;
  padding:40px;
}


.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.react-slideshow-wrapper.slide {
  height: 200px;
}

.react-photo-album--row{
 margin-right: 100px;
 margin-left: 100px;
 margin-bottom: 30px;
}

.react-photo-album--photo{
 height: 250px !important;
 }
.info-title-area{
  color: #787D3C;
  font-weight: bold;
  margin-left: 40px;
}

.special-area{
  background-color: #C0C371;
  border:1px solid #C0C371;
  width: 100px;
  height: 4px;
  position: relative;

  bottom: 15px;
}
/*
.special-area:hover{
  background-color: #C0C371;
  border:1px solid #C0C371;
  width: 270px;
  height: 4px;
  position: relative;
  right: 40px;
  bottom: 15px;
  transition: all .3s ease;
}
*/
.moveArrow {
  animation-name : mymove;
  animation-iteration-count : infinite;
  animation-direction : alternate;
  animation-duration : 2s;
}

@keyframes mymove {
  from {transform: translateY(0px);}
  to {transform: translateY(50px);}
}

.image {
  height: 100vh;
  position: relative;
  
}
.img-wrapper{
  overflow:hidden;
  width: 400px;
  height: 400px;
}
img.hover-zoom:hover {
  transform: scale(1.25);
}

.newsletter-area{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#4B4F52;
position: relative;
  
} 

.newsletter-text-area{
  font-size: 18px;
  color:black;
  margin-bottom: 0rem;
}

.newsletter-text-bold{
  font-weight: bold;
  color: #FFD058;
  font-family: mynaruse;
}
.newsletter-text-message{
  font-family: mynaruse;
  color: white;
}
.newsletter-input{
  border:3px solid #FFD058;
  padding:8px;
  border-radius: 8px;
  width: 300px;
  margin-left: 200px;
}
.newsletter-button{
  color:white;
  border:1px solid #FFD058;
  background-color:#FFD058;
  padding:10px;
  border-radius: 8px;
  width: 100px;
  margin-left: 20px;
  font-family: mynaruse;

}


.footer-area {
  display: flex;  
  padding-top:100px;
  padding-bottom:120px;
 justify-content: space-around;
 
}

.footer-area-home {
  display: flex;  
  justify-content: space-around;
}

.footer-area-link {
  text-decoration: none;
  color: white;
  padding-top:3px;
  cursor: pointer;
}


.footer-area-link-a {
  font-size: 14px;
  font-family: mynaruse;
  color:rgba(165, 160, 160, 1)
}


.footer-area-title {
  font-weight: 900;
  color:#FFD058;
  margin-bottom:10px;
  font-family: mynaruse;
  font-size: 15px;
}

.navbar-link {
  text-decoration: none;
  color: #FFD058;
  margin-top:8px;
  margin-right:30px;
  font-weight:bold;
  font-family: future;
  font-size: 14px;
}

.nav-link {
  color: #FFD058 !important;
  margin-right:30px;
  font-weight: bold !important;
}

.nav-link:focus, .nav-link:hover {
  color: #D39801 !important;
}


.home-gallery-area{
  display: flex;
  height: 426px;
}
.home-gallery-area2{
  height: 650px;
}

.home-gallery-area-header{
  font-family: future;
  font-weight: bold;
  color: white;
}

.home-title{
  font-family: future;
  margin-bottom: 30px;
  font-weight: bold;
  color: #FFD058;
  font-size: 23px;
}

.home-desc{
  font-family:  roboto;
  margin-top: 30px;
  color: white;
  font-size: 18px;
  font-weight: 700;
}

.login-title-area{
  font-family:  Helvetica;
  margin-top: 30px;
  margin-bottom:30px;
  color: #4B4F52;
  font-size: 30px;
  font-weight: 700;
}
.home-gallery-area-img{
  width: 50%;
}
.home-gallery-text-area{
 position: relative;
 top: -200px;
 left: 40px;
}

.home-gallery-text-area{
  position: relative;
  top: -250px;
  left: 40px;
  width: 300px;
 }
 .home-gallery-area-button{
  font-family: future !important;
  background-color: transparent !important;
  color: white !important;
  padding: 05px 20px !important;
  font-weight: 900 !important;
  border:1px solid white !important;
  margin-top:15px;
  border-radius: 5px;
  font-size: 20px !important;
 }

 .home-gallery-img{
   width: 100%;
 }

 .contact-title{
  margin:20px;
  font-family: mynaruse;
  margin-bottom: 60px;
  color:#4B4F52;
  font-size: 35px;
  font-weight: bold;
 }

.home-gallery-text-area2{
  position: relative;
  top: -250px;
  left: 400px;
  width: 600px;
} 
.home-gallery-text-area-first
  {
    position: relative;
    top: -430px;
    left: 400px;
    width: 600px;
  }

.info-area{
 padding:100px 150px;
}

.info-area-inside{
  padding:0px 100px;
}
.home-page-title {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 9vmin;
  font-weight: bold;
  color: #DE9972;
}


.contact-adres-area{
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom:40px;
  padding-top:40px;
}
.contact-adres-area-title{
  color:#FFD058;
  text-align: left;
  white-space: pre-line;
vertical-align: bottom;
}
.contact-adres-area-desc{
  color:black;
  white-space: pre-line;
vertical-align: bottom;
}
.contact-adres{
  width: 50%;
  text-align: left;
  box-shadow: 0px 20px 50px rgb(182, 181, 181);
  border: none;
  padding: 30px;
  box-shadow: rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin-right: 30px;
  margin-left: 30px;
}

.map{
  height: 80vh;
  margin: auto;
  margin-top: 20px ;
  
}
.contact-area{
  background-color: rgba(255, 249, 241, 1);
 
}

.row{
  margin-top: 3%;
  display: flex;
  justify-content: space-between;
}
h3{
  text-align: center;
  font-weight: 600;
  margin: 10px 0;
}


.blur{
  filter: blur(5px);
}

.popover{
  background-color: transparent !important;
  color:white !important;
  font-size: 20px !important;
 z-index: 0 !important;
  border:none !important;
  transition: opacity .3s ease !important;
}

.popover-header{
  background-color: transparent !important;
  color:white !important;
  font-size: 20px !important;
  transition:  4s  !important;
  position: relative;
right: 100px;
border:none !important;

 
}
.popover-body{
  background-color: transparent !important;
  color:white !important;
  transition:  1s  !important;
  width: 800px;
  position: relative;
right: 300px;
border:none !important;

}
.login-input{
  border:3px solid #FFD058;
  padding:8px;
  border-radius: 8px;
  width: 300px;
  margin-bottom: 10px;
}
.login-button{
  color:white;
  border:1px solid #FFD058;
  background-color:#FFD058;
  padding:10px;
  border-radius: 8px;
  width: 100px;
  margin-bottom: 10px;
}
.marker-style{
  color: red;
  font-size: 50px;
}
.marker-text{
  font-family: mynaruse;
  color:#4B4F52;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin-left: -20px;
}
.popup{
  position: absolute;
  inset: 50px;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 40px;
  font-size: 15px;
}
.popup-realestate{
  background: url('/src/images/real-open.jpg') center center/cover no-repeat; 
  color:white;
}
.popup-agri{
  background: url('/src/images/agri-open.jpg') center center/cover no-repeat; 
  color:white;
}
.popup-medical{
  background: url('/src/images/medi-open.jpg') center center/cover no-repeat; 
  color:white;
}
.close-button{
  border:none;
  float: right;
  background-color: transparent;
  font-size:25px;
  color:white;
  position: relative;
  left: 30px;

}
.title-popup{
  font-family: future;
}
.navbar-toggler{
  background-color: #383E42 !important;
}
.navbar-toggler-icon{
  background-image: url('/src/images/navbar-cizgi.svg') !important; 
}

.navbar-link:hover{
  color: #D39801 !important;
}
.footer-area-link:hover{
  color: #D39801 !important;
}

.swiper {
  width: 100% !important;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.swiper-slide {
  background-position: center !important;
  background-size: cover !important;
  width: 400px !important;

}

.swiper-slide img {
  display: block !important;
  width: 100% !important;
}


.login-area{
 text-align:  center;
 margin-top:200px;
 margin-bottom: 200px;
}

.previous-title{
  color:rgba(108, 112, 52, 1);
  margin-bottom:50px;
  font-weight: 700;
  text-align: left;

}
.previous-area { 
  padding-top:150px;
  padding-left:150px;
  padding-right:150px;
  padding-bottom:80px;
  font-family: Arial, Helvetica, sans-serif;
}

.previous-desc{
  white-space: pre-line;  
  vertical-align: bottom;
  text-align: left;
}
.previous-logo-smou { 
  width: 50%;
}

.previous-logo { 
  width: 50%;
}

.previous-logo-desc { 
  width: 40%;
}
.previous-logo-damas { 
 
 width: 40%;
 
}
.previous-logo-royal { 
 
  width: 50%;
 }

 .previous-logo-ahkam{
  width: 50%;
 }
.previous-area2{
  padding-left: 150px;
  text-align: center;
  padding-top:0px;
  padding-bottom: 50px;
}

.previous-page{
  background-color: rgba(255, 249, 241, 1);
}
.previous-link {
  text-decoration: none;
  color: black;
}
.about-title{
  color:white;
  font-weight: bold;
  font-family: mynaruse;
  font-size: 60px;
}
.previous-all-area {
 text-align: center;
 /*padding:100px*/
}

.text-area-work{
  align-items: center;
  height: 600px;
  width: 100%;
  display: flex;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  padding: 100px;
  margin-top: 20px;
}
.previous-work-smou{
  background: url('/src/images/alsmouback.png') center center/cover no-repeat; 
}
.previous-work-damas{
  background: url('/src/images/damasback.png') center center/cover no-repeat; 
}
.previous-work-royal{
  background: url('/src/images/royalback.png') center center/cover no-repeat; 
}
.previous-work-ahkam{
  background: url('/src/images/ahkamback.png') center center/cover no-repeat; 
}
.text-area-work-half{
  width: 50%;
}
.text-area-work-half-text{
  width: 50%;
  text-align: left;
  font-weight: bold;
  font-size: 20px;
}
.popover-arrow{
  display: none !important;
}

@media(max-width:700px){
  .row{
      flex-direction: column;
  }
  .previous-logo-damas {
    width: 50%;
    left: 0;
    top: 0;
   }
   .previous-logo-royal { 
     width: 60%; 
     right: 0;
    }
    .previous-logo-ahkam{
     width: 50%;
    }
    .about-desc{
      padding-right:40px;
      padding-left: 40px;
     
    }
    
}
@media screen and (max-width: 600px) {
  .about-ceo-area-inside  {
    display: block;
  }
}
@media screen and (min-width: 730px)  and  (max-width: 920px){
  
  .about-ceo-image{
    width: 150%;
  }
}

@media screen and (min-width: 730px) {
  .about-ceo-area-inside  {
    display: flex;
    padding-left: 80px;
    padding-bottom: 30px;
    padding-top:50px;
  }
  .about-ceo-image{
    padding: 0;
  }
}
@media screen and (max-width: 730px) {
  .about-ceo-area  {
   display: block;
   margin-bottom: 0px;
   margin-top:10px;
  }
  .about-area{
    padding:0px;
  }
  
  .previous-area { 
    padding:50px;
  }
  .previous-area2{
    padding-left: 0;
    padding-bottom:0;
    padding-top:30px
  }
  .previous-logo-desc{
    width: 100% !important;
    padding: 50px;
  }  
  
}

@media screen and (max-width: 768px) {
  .newsletter-area {
    display: block !important;
    padding:30px;
    position: relative;
  }
  .newsletter-text-area{
    margin-right:0;
    margin-bottom:50px;
  }
  .newsletter-input{
    margin-left: 0px;
  }
  .footer-area-title {
    font-size: 12px;
  }
  .footer-area-link-a {
    font-size: 12px;
  }
  
  
}

@media screen and (min-width: 768px) {
  .newsletter-area {
    display: flex !important;  
    padding-top:30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    position: relative;
    justify-content: space-evenly;
  }  
  .footer-area {
    display: flex;  
    padding-top:30px;
    padding-bottom:30px;
   justify-content: space-around;
  }

  
}
@media screen and (max-width: 600px) {
  .footer-area {
    display: block !important;  
    padding-left: 30px;
    padding-top:30px;
    padding-bottom: 30px;
  }

  .hero-container{
    padding-top: 400px !important;
    padding-bottom: 100px !important;
    padding-left: 60px !important;
    height: 700px !important;
   }
   .about-image{
    padding-top: 400px !important;
    padding-bottom: 100px !important;
    padding-left: 60px !important;
    height: 400px !important;
   }
   .contact-image{
    padding-top: 400px !important;
    padding-bottom: 100px !important;
    padding-left: 60px !important;
    height: 400px !important;
   }
   .vision-image{
    padding-top: 400px !important;
    padding-bottom: 100px !important;
    padding-left: 60px !important;
    height: 400px !important;
   }
   .ceo-image{
    padding-top: 400px !important;
    padding-bottom: 100px !important;
    padding-left: 60px !important;
    height: 400px !important;

   }
   .previous-image{
    padding-top: 400px !important;
    padding-bottom: 100px !important;
    padding-left: 60px !important;
    height: 400px !important;

   }
}


@media screen and (max-width: 900px) {
  .home-gallery-area{
    display: block !important;
    height: auto !important;
  }
  .home-gallery-area-img{
    width: 100% !important;
    height: 320px !important;
  }
  .home-gallery-area2{
    height: 190px !important;
  }
  .home-gallery-text-area2{
    position: relative;
    top: -170px;
    left: 80px;
    width: 200px;
  }
  .info-area{
   padding:0px !important;
  }
  .home-gallery-area-header{
    font-size:20px;
  }
  .home-desc{
    font-size:15px;
  }
  .newsletter-text-bold{
    font-size:1.2rem !important;
  }
  .newsletter-text-message {
   font-size: 1rem !important;
  }
  .newsletter-input{
    width: 250px !important;
  }
  .footer-area-action{
    margin-bottom:50px;
  }
   
}
@media screen and (max-width: 541px) {
  .home-gallery-text-area2{
    left: 100px !important;
    top: -130px !important;
 } 
 .home-gallery-text-area-first
 {
  width: 400px !important;
    top: -200px !important;
   left: 10px !important; 
 }
 .about-vector{
  left: 150px !important;
}
.mv-text-area{
  padding: 0;
}
.about-title{
  margin-right: 30px;
  margin-bottom: 250px;
  font-family: mynaruse;
  font-size: 30px;
}

}
@media screen and (max-width: 375px) {
   .home-gallery-text-area2{
    top: -80px !important;   
  } 
  .about-vector{
    position: relative;
    top: 0px !important;
    left: 280px !important;
  }
}

@media screen and (min-width: 760px) and (max-width: 820px) {
  .newsletter-button{
    margin-left: 0px !important;
    margin-top: 20px !important;
   }
}


@media screen and (max-width: 470px) {
  .newsletter-button{
    margin-left: 10px !important;
   }
   .total-area{
    display: block !important;
    padding: 0px !important;
  }
  .about-vector{
    position: relative;
    top: 0px !important;
    left: 280px !important;
  }
  .mv-text-area{
    padding:0 !important;
  }
 
  .mission-area{
    width: 100% !important;
    margin-bottom:100px;
  }
  .vision-area{
    width: 100% !important;
    margin-bottom:100px;
  }
  .ceo-title {
    text-align: center;
    padding:0;
  }
}

@media screen and (max-width: 415px) {
  .home-gallery-area2{
    height: 165px !important;
  }
  .newsletter-button{
    margin-left: 0 !important;
    margin-top: 20px !important;
   }
 
  .home-gallery-area-button{
    margin-top: 0 !important;
    font-size: 10px !important;
   }
   .home-gallery-text-area2{
    top: -100px !important;
    left: 40px !important;
    width: 300px !important;
  }
  .home-gallery-text-area-first
  {
    top: -170px !important;
    left: 40px !important;
    width: 300px !important;
  }
  .popover-body{
    font-size: 10px !important;
    width: 375px !important;
    padding: 0px !important;
    right: 90px !important
   }
   .contact-adres-area{
    display: block !important;
   }
  .contact-adres{
    width: 100% !important;
    margin-top:30px;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .map {
    height: 50vh !important;
    width: 80% !important;
    margin-top: 20px ;
  }

}

@media screen and (max-width: 600px) {
  .contact-adres-area{
    display: block !important;
   }
  .contact-adres{
    width: 100% !important;
    margin-top:30px;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .map {
    height: 50vh !important;
    width: 80% !important;
    margin-top: 20px ;
  }
  .home-gallery-area-button{
    margin-top: 0 !important;
    font-size: 10px !important;
   }

.navbar-link-dropdown{
  margin-bottom:0px !important;

}
}

@media screen and (min-width: 580px) and  (max-width: 620px) {
  .home-gallery-text-area-first
  {
    top: -240px !important;
    left: 10px !important;
    width: 540px !important;
  }
  .home-gallery-text-area2
  {
    top: -180px !important;
    left: 20px !important;
    width: 540px !important;
  }
}

@media screen and (min-width: 620px) and  (max-width: 680px) {
  .home-gallery-text-area-first
  {
    top: -290px !important;
    left: 30px !important;
    width: 540px !important;
  }
  
  .home-gallery-text-area2
  {
    top: -180px !important;
    left: 20px !important;
    width: 540px !important;
  }
}
@media screen and (min-width: 680px) and  (max-width: 720px) {
  .home-gallery-text-area-first
  {
    top: -320px !important;
    left: 30px !important;
    width: 540px !important;
  }
  
  .home-gallery-text-area2
  {
    top: -200px !important;
    left: 50px !important;
    width: 540px !important;
  }
}


@media screen and (min-width: 720px) and  (max-width: 780px) {
  .home-gallery-text-area-first
  {
    top: -350px !important;
    left: 30px !important;
    width: 600px !important;
  }
  
  .home-gallery-text-area2
  {
    top: -220px !important;
    left: 50px !important;
    width: 600px !important;
  }
}



@media screen and (min-width: 780px) and  (max-width: 800px) {
  .home-gallery-text-area-first{
    top: -380px !important;
    left: 80px !important;
    width: 600px !important;
  }
  .home-gallery-text-area2
  {
    top: -250px !important;
    left: 80px !important;
    width: 600px !important;
  }
}

@media screen and (min-width: 800px) and  (max-width: 890px) {
  .home-gallery-text-area-first{
    top: -400px !important;
    left: 100px !important;
  }
  .home-gallery-text-area2
  {
    top: -250px !important;
    left: 80px !important;
    width: 600px !important;
  }
}

@media screen and (min-width: 900px) and  (max-width: 1000px) {
  .home-gallery-text-area-first{
    top: -350px !important;
    left: 100px !important;
  }
  .home-gallery-text-area2
  {
    top: -200px !important;
    left: 80px !important;
    width: 600px !important;
  }
  .newsletter-input{
    margin-left: 0px !important;
  }
}
@media screen and (min-width: 1000px)  {
  .home-gallery-text-area-first{
    top: -200px !important;
    left: 230px !important;
  }
  .home-gallery-text-area2
  {
    top: -200px !important;
    left: 170px !important;
    width: 600px !important;
  }
  /*
  .real-estate-action{
    top:100px !important;
  }
  .agriculture-action{
    top:100px !important;
  }
  .medical-action{
    top:100px !important;
  }
  */
  .newsletter-input{
    margin-left: 15px !important;
  }
}

.popover .text {
  position: absolute;
  bottom: -40px;
  left: 45%;
 cursor: pointer;
 pointer-events: none;
}


.mail-list-items{
  width: 300px;
}


@media screen and (width: 540px) {
  .home-gallery-text-area-first{
    top: -280px !important;
    left: 80px !important;
    width: 300px !important;
  }
  .home-gallery-text-area2
  {
    top: -180px !important;
    left: 100px !important;
    width: 300px !important;
  }
}




@media screen and (width: 1024px) {
  .agriculture-action {
      position: relative;
      top: 0px;
  }
  .real-estate-action {
    position: relative;
    top: 0px;
}
.medical-action {
  position: relative;
  top: 0px;
}
}

.navbar-link-dropdown{
  margin-right: 0px !important;
  margin-bottom:10px;
  font-size: 14px !important;

}

.dropdown-toggle{
  font-family: future !important;
}

.history-title{
  color:#4A8C50;
  font-family: mynaruse ;
}

.history-area{
  padding: 100px;
}

.vertical-timeline-element-date{
  color:#4A8C50;
  font-family: mynaruse ;
  font-size:20px !important;
  font-weight: bold !important;
}

.vertical-timeline {
  width: 110% !important;
}

.dropdown-menu{
  padding:5px !important; 
  background-color: #383E42!important;
}
.dropdown-item{
  font-family: future !important;
  font-size: 14px !important;
  color:#FFD058 !important
}



.contact-adres-area-desc-phone{
  cursor: pointer;
}