
/*
video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.hero-container {
  background: url('/src/images/img-home.jpg') center center/cover no-repeat; 
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -200px;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

.test {
  width: 100%;
}

@media screen and (max-width: 960px) {
  .stoppedImage {
    background: url("/src/images/mobile.jpeg") center center/cover no-repeat;
    width: 100%;
    height: 100%;
  }
}
*/

.hero-container {
  background: url('/src/images/backgroundTigaya.png') center center/cover no-repeat; 
  height: 961px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  padding:400px 20px;
  padding-bottom: 100px;
}


.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -200px;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}



@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }
  .hero-container > p {
    font-size: 30px;
  }
}


@media screen and (min-width: 895px) and (max-width: 930px) {
  .home-gallery-area2{
    height: 400px !important;
  }
  
}


@media screen and (min-width: 580px) and  (max-width: 895px){
  .left-side {
    width: 100% !important;
    position: relative;
    top:0px !important;
    left: 150px !important;
  } 
  .hero-container{
    height: 600px !important;
    padding:0px !important;
  }
  .about-image{
    height: 600px !important;
    padding:0px !important;
  }
  .contact-image{
    height: 600px !important;
    padding:0px !important;
  }
  .vision-image{
    height: 600px !important;
    padding:0px !important;
  }
  .ceo-image{
    height: 600px !important;
    padding:0px !important;
  }
  .home-gallery-area2{
    height: 400px !important;
  }
  
  .home-gallery-area2{
    height: 260px !important;
  }
  .about-vector{
    left: 200px !important;
  }
}
@media screen and (min-width: 800px) and  (max-width: 895px){
 
  .newsletter-input{
    margin-left: 15px !important;
  }
  .about-vector{
    left: 230px !important;
  }
  .mission-area{
    width: 100%;
    padding: 0;
  }
  .vision-area{
    width: 100%;
    padding: 0;
  }
}
 
@media screen and (min-width: 900px) and  (max-width: 950px){
 .about-vector{
    left: 270px !important;
  }
  .mission-area{
    width: 100%;
    padding: 0;
  }
  .vision-area{
    width: 100%;
    padding: 0;
  }
}

.left-side{
  width: 70% !important;
  position: relative !important;
  left: 30px !important;
  top:-130px !important;
}
.right-side {
  float: right;
  width: 60%;
  opacity: 0.3;
  
}





